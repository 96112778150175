<template>
  <div class="card">
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <th>Admission no</th>
          <th>Admission date</th>
          <th>Discharge date</th>
          <th>Ward / Cabin no</th>
          <th>Consultant</th>
          <th>Admitted Department</th>
          <th>Patient Name, Age, gender</th>
          <th>Patient Mobile No</th>
          <th>Status</th>
          <th class="text-right">Total Bill Amount</th>
          <th class="text-right">Total Paid Amount</th>
          <th class="text-right">Total Due Amount</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in lists" :key="i">
          <td>{{ item.admission_no }}</td>
          <td>{{ item.admission_date }}</td>
          <td>{{ item.service_general && item.service_general.delivery_date_time }}</td>
          <td>{{ item.service_resource && item.service_resource.name }}</td>
          <td>{{ item.human_resource && item.human_resource.name }}</td>
          <td>{{ item.department && item.department.name }}</td>
          <td>{{ item.patient.full_name }}, {{ item.patient.birthday }}, {{ item.patient.gender }}</td>
          <td>{{ item.patient.mobile_no }}</td>
          <td class="capitalize">{{ item.service_general && item.service_general.completion_status }}</td>
          <td>{{ commaFormat(item.bill_amount) }}</td>
          <td>{{ commaFormat(item.paid_amount) }}</td>
          <td>{{ commaFormat(item.due_amount) }}</td>
        </tr>
        <tr>
          <th colspan="9" class="text-right">Total</th>
          <th class="text-right">{{ commaFormat(total.bill_amount) }}</th>
          <th class="text-right">{{ commaFormat(total.paid_amount) }}</th>
          <th class="text-right">{{ commaFormat(total.due_amount) }}</th>
        </tr>
        </tbody>
      </table>
      <p v-if="!lists.length" class="text-center mt-5">No data</p>
    </div>
  </div>
</template>

<script setup>
import figureFormatter from "@/services/utils/figureFormatter";

const props = defineProps({
  lists: Array,
  total: Object
})

const { commaFormat } = figureFormatter();
</script>
