<template>
  <div class="card">
    <div class="bg-blue-light p-1">
      <TitleButton
          :showBtn="false"
          :showAddNew="false"
          title="IPD Patient Status Report"
      />
    </div>

    <div class="my-2 px-2">
      <div class="row">
        <div class="col-12 mb-1">
          <div class="row">
            <div class="col-12 col-sm-6 col-md-4 col-lg-3">
              <label for="colFormLabel" class="col-form-label">Status: </label>
              <v-select
                  v-model="status" class="w-100" placeholder="Status"
                  :options="statuses" label="name" :reduce="(name) => name.id"
              />
            </div>
            <div v-show="status === 'completed'" class="col-12 col-sm-6 col-md-4 col-lg-3">
              <label
                  for="colFormLabel"
                  class="col-form-label"
              >
                Start Date
              </label>
              <input
                  @change="updateUrl"
                  v-model="start"
                  type="date"
                  class="form-control date-mask"
                  placeholder="From"
              >
            </div>
            <div v-show="status === 'completed'" class="col-12 col-sm-6 col-md-4 col-lg-3">
              <label
                  for="colFormLabel"
                  class="col-form-label"
              >
                End Date
              </label>
              <input
                  @change="updateUrl"
                  v-model="end"
                  type="date"
                  class="form-control date-mask"
                  placeholder="From"
              >
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Bed / Cabin: </label>
          <v-select
              v-model="service_resource_id" placeholder="Select bed/cabin" class="w-100"
              :options="beds" label="name" :reduce="(name) => name.id"
          />
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Consultant: </label>
          <v-select
              v-model="human_resource_id" class="w-100" placeholder="Consultant"
              :options="consultants" label="name" :reduce="(name) => name.id"
          />
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Department: </label>
          <v-select
              v-model="department_id" class="w-100" placeholder="Department"
              :options="departments" label="name" :reduce="(name) => name.id"
          />
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3 d-flex align-items-end">
          <button
              style="min-width: 64px;"
              @click="getReport"
              class="btn btn-primary btn-block waves-effect waves-float waves-light"
          >Go
          </button>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex align-items-end mt-2 justify-content-end">
          <button
              @click="goToPrint"
              class="btn btn-block btn-secondary ms-1 waves-effect waves-float waves-light"
          >Print
          </button>
        </div>
      </div>
    </div>

    <div class="col-12 px-2">
      <ListTable :total="total_amount" :lists="lists"/>
    </div>
    <div class="mb-2"></div>

    <div class="px-2 position-absolute bottom-0">
      <Pagination
          :offset="offset"
          @onPageChange="onPageChange"
          ref="pagination"
      />
    </div>
    <Loader v-if="loading"/>
  </div>
</template>

<script setup>
import TitleButton from '@/components/atom/TitleButton';
import {useRoute, useRouter} from 'vue-router';
import Pagination from "@/components/atom/Pagination.vue";
import ListTable from '@/components/molecule/company/hospital/hospital-admin/IPDPatientStatusReportTable.vue';
import handleHospitalReport from "@/services/modules/hospital/report";
import {computed, inject, onMounted, ref} from "vue";
import Loader from "@/components/atom/LoaderComponent.vue";
import {useStore} from "vuex";
import handleHospital from "@/services/modules/hospital";
import handleCBusinesses from "@/services/modules/businesses";
import ipdStatusReportPdf from '@/services/utils/pdf/ipdReportStatus';
import handleCompany from "@/services/modules/company";

const {fetchBusinessList} = handleCBusinesses()
const {fetchServiceList, fetchDepartList} = handleHospital()
const {iPDPatientStatusReport} = handleHospitalReport()
const { formatStatusReportData, exportToPDF } = ipdStatusReportPdf();
const { fetchCompanyInfo } = handleCompany();
const showError = inject('showError');
const showSuccess = inject('showSuccess');
const store = useStore()
const route = useRoute();
const router = useRouter();
const loading = ref(false);
const beds = ref([])
const businesses = ref([])
const consultants = ref([])
const departments = ref([])
const statuses = ref([
  {id: 'pending', name: 'Admitted'},
  {id: 'completed', name: 'Released'},
])
const lists = ref([])
const total_amount = ref({
  bill_amount: 0,
  paid_amount: 0,
  due_amount: 0,
})
const date = ref()
const start = ref(route.query.start);
const end = ref(route.query.end);
const service_resource_id = ref(null)
const human_resource_id = ref(null)
const department_id = ref(null)
const business_id = ref(null)
const status = ref('pending')
const token = localStorage.getItem('token');
let offset = ref(20)
const isFetchAll = ref(false);
const statusPdfReports = ref([]);
const totalPdfAmount = ref({
  bill_amount: 0,
  paid_amount: 0,
  due_amount: 0
});

const companyInfo = ref({})

//computed
const companyId = computed(() => route.params.companyId);
const dateValue = computed(() => route.query.date);

//methods
const getQuery = () => {
  let query = '?company_id=' + companyId.value
  if (dateValue.value) query += '&date=' + dateValue.value
  if (!!status.value) query += '&status=' + status.value
  if (!!business_id.value) query += '&business_id=' + business_id.value
  if (!!service_resource_id.value) query += '&service_resource_id=' + service_resource_id.value
  if (!!human_resource_id.value) query += '&human_resource_id=' + human_resource_id.value
  if (!!department_id.value) query += '&department_id=' + department_id.value
  if (start.value) query += '&start_date=' + start.value
  if (end.value) query += '&end_date=' + end.value
  if (isFetchAll.value) query += '&is_fetch_all=1';
  return query
};
const updateUrl = () => {
  let routeQuery = Object.assign({}, route.query)
  routeQuery.start = start.value
  routeQuery.end = end.value
  router.push({path: route.path, query: routeQuery})
}
const getReport = async () => {
  loading.value = true
  await iPDPatientStatusReport(getQuery()).then(({status, data, total}) => {
    if (!status) {
      lists.value = []
      return resetPagination()
    }

    if(isFetchAll.value) {
      statusPdfReports.value = data;
      return;
    }

    lists.value = data.data
    total_amount.value = total

    if (data.data.length > 0) {
      setPagination(data)
    }
  }).catch((err) => {
    loading.value = false
  }).finally(() => {
    loading.value = false
  })
};

const goToPrint = async () => {

  statusPdfReports.value = [];
  const payloads = getPayloads();
  isFetchAll.value = true;
  
  await getReport().then(() => {
    if(!statusPdfReports.value.length) return;
    const formattedData = formatStatusReportData(statusPdfReports.value);
    exportToPDF(companyInfo.value, formattedData, payloads)
  })
  
  isFetchAll.value = false;
}

const getPayloads = () => {
  return {
    date: dateValue.value || '',
    business: business_id.value && getRecordBy('id', business_id.value, businesses.value),
    serviceResource: service_resource_id.value && getRecordBy('id', service_resource_id.value, beds.value),
    humanResource: human_resource_id.value && getRecordBy('id', human_resource_id.value, consultants.value),
    department: department_id.value && getRecordBy('id', department_id.value, departments.value),
    status: status.value
  };
}

const getRecordBy = (key, value, records) => {
  return records.find(el => el[key] == value).name
}

const setPaginationData = (data) => {
  store.commit('setPaginationData', data)
};
const resetPagination = () => {
  store.commit('resetPagination')
};
const setPagination = (data) => {
  setPaginationData({
    records: data.total,
    perPage: data.per_page,
    currentPage: data.current_page,
  })
}
const onPageChange = async (page) => {
  let routeQuery = Object.assign({}, route.query)
  routeQuery.page = page
  await router.push({path: route.path, query: routeQuery})
  await getReport()
}

onMounted(async () => {
  loading.value = true;
  date.value = route.query.date
  const query = `?company_id=${companyId.value}&search_type=all`;
  await Promise.all([
    fetchCompanyInfo(companyId.value).then((res) => {
      if(res.status) companyInfo.value = res.data
    }),
    fetchServiceList(query, 'places').then(({status, data}) => {
      if (status) beds.value = data
    }),
    fetchServiceList(query, 'human_resources').then(({status, data}) => {
      if (status) consultants.value = data
    }),
    fetchDepartList(query).then(({status, data}) => {
      if (status) departments.value = data
    }),
    fetchBusinessList(query).then(({status, data}) => {
      if (status) businesses.value = data
    }),
  ]).then(() => {
    loading.value = false;
  }).catch((err) => {
    loading.value = false
  });
})

</script>
